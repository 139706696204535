<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <!-- <v-row>
      <v-col>
        <span
          style="cursor: pointer"
          @click="navBack"
        >
          <v-icon
            style="
            font-size: 16px;
            color: #7A1878;
          "
          >
            fas fa-chevron-left
          </v-icon>
          <span
            class="ml-1"
            style="color: #7A1878"
          >
            Kembali
          </span>
        </span>
      </v-col>
    </v-row> -->

    <v-row class="mt-n5">
      <v-col>
        <!-- Riwayat Transaksi Penyewa -->
        <v-card class="px-4 py-4">
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="mt-2 mb-3"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cari"
                color="purple"
                single-line
                hide-details
                clearable
                outlined
                dense
                @keyup="filterData"
                @click:append="filterData"
                @click:clear="filterClear"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-data-table
                :headers="headerCommission"
                :items="commission"
                :items-per-page="25"
                class="elevation-0"
                mobile-breakpoint="100"
                hide-default-footer
              >
                <template v-slot:item.code="{ item }">
                  <div
                    class="font-common"
                    style="
                      color: #7A1878;
                      cursor: pointer;
                    "
                    @click="toOrderDetail(item.hash)"
                  >
                    {{ item.code }}
                  </div>
                </template>

                <template v-slot:item.trx_total="{ item }">
                  <div class="currency-format">
                    <span>
                      Rp
                    </span>

                    <span>
                      {{ (item.trx_total || 0) | dotThousandSeparator }}
                    </span>
                  </div>
                </template>

                <template v-slot:footer>
                  <div class="text-center my-5 py-3">
                    <v-pagination
                      v-model="pageCommission"
                      :length="pageCountCommission"
                      color="purple"
                      :total-visible="5"
                      @input="onChangePageCommission"
                    />
                    <span style="font-size: 12px; font-style: italic;">
                      Page {{ pageCommission }} of {{ pageCountCommission || 1 }}
                    </span>
                  </div>
                  <v-row class="mt-n16">
                    <v-col class="text-right pr-8">
                      <span style="font-size: 12px; font-style: italic;">
                        {{ itemCountCommission + ' dari ' + itemTotalCommission + ' ditampilkan' }}
                      </span>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      search: null,

      // Commission Table:
      headerCommission: [
        { text: 'Reseller', value: 'user_name' },
        { text: 'Kode Pesanan', align: 'start', value: 'inv_code', sortable: false },
        { text: 'Tanggal Pesanan', value: 'created_time' },
        { text: 'Nominal', value: 'trx_total' },
        { text: 'Komisi', value: 'commission_percent' },
        { text: 'Nominal Komisi', value: 'commission_nominal' },
        { text: 'Status', value: 'status_string', sortable: false },
        { text: 'Tanggal Tayang', value: '' },
      ],
      commission: [],

      pageCommission: 1,
      pageCountCommission: 0,
      itemCountCommission: 0,
      itemTotalCommission: 0,

      // Permission:
      userMenus: localStorage.getItem('userMenus').split(','),
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),

      newsLetter () {
        let r = ''
        this.detail.newsletter_subscribe ? r = 'Langganan' : r = 'Tidak Berlangganan'

        return r
      },

      customerEdit () {
        let v = false
        if (this.userMenus.includes('CUSTOMER_EDIT')) v = true

        return v
      },
    },

    watch: {
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize (page, limit) {
        const vm = this

        const requestBody = {
          status: '',
          page: page,
          limit: limit,
        }

        axios.post('/v2/t/commission', requestBody).then((res) => {
          if (res.data.status === 200) {
            console.log(res)
            vm.commission = res.data.data.list
            // pagination:
            vm.pageCountCommission = res.data.data.pagination.page_total
            vm.itemCountCommission = res.data.data.pagination.item_count
            vm.itemTotalCommission = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      onChangePageCommission (e) {
        this.initialize(e)
      },

      filterData () {
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        this.timer = setTimeout(() => {
          this.initialize(1, this.itemsPerPage, this.search, this.status)
        }, 800)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage, '', this.status)
      },
    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-size: 16px;
  color: #808080;
}

.font-common-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #808080;
}

.font-common-black {
  font-family: Lato;
  font-size: 16px;
  color: #333333;
}

.font-common-black-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.font-common-white {
  font-family: Lato;
  font-size: 16px;
  color: #FFFFFF;
}

.font-common-white-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

/* MODAL IMAGE  */
#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}
#myImg:hover {
  opacity: 0.7;
}
.modal {
  z-index: 10;
  display: none;
  position: fixed;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}
.modal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
/* END OF MODAL IMAGE */
</style>
